import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"

import Sidebar from "../components/sidebar/Sidebar"
import TechTag from "../components/tags/TechTag"


const ProfilePage = (props) => {
    const labels = props.data.site.siteMetadata.labels
    const profileTags = ["php", "go", "javascript", "ts", "vue", "react"]

    const getTechTags = (tags) => {
        const techTags = []
        tags.forEach((tag, i) => {
            labels.forEach((label) => {
                if (tag === label.tag) {
                    techTags.push(<TechTag key={i} tag={label.tag} tech={label.tech} name={label.name} size={label.size} color={label.color} />)
                }
            })
        })
        return techTags
    }

    return (
        <Layout>
            <SEO title="Profile" />
            <div className="post-page-main">
                <div className="sidebar px-4 py-2">
                    <Sidebar />
                </div>

                <div className="post-main">
                    <SEO title="Profile"/>
                    <div className="mt-3">
                        <h2 className="heading">Profile</h2>
                        <p>
                            Webエンジニアの
                            <a className="text-info" href={props.data.site.siteMetadata.contacts.github}>
                                {props.data.site.siteMetadata.author}
                            </a>
                            です。
                        </p>
                        <h4>興味のある分野</h4>
                        <p>以下の分野に興味があります。</p>
                        <div className="d-block">
                            {getTechTags(profileTags)}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query profileQuery {
        site {
            siteMetadata {
                author
                contacts {
                    github
                }
                labels {
                    tag
                    tech
                    name
                    size
                    color
                }
            }
        }
    }
`

export default ProfilePage